import React from "react";

import Layout from "@components/Layout";
import SEO from "@components/Seo";

import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO
        seo={{
          metaTitle: "404: Not found",
          metaDescription: "It looks like you got lost",
        }}
      />
      <div className="container pt-[120px]">
        <h1>{t('notFound.title')}</h1>
        <p>{t('notFound.message')}</p>
      </div>
    </Layout>
  )
};

export default NotFoundPage;
